import Button from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import * as React from 'react'

interface TDialog {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    onOk: () => void
    children?: React.ReactNode | React.ReactNode[]
    position?: 'top' | 'bottom'
}

const TransitionDown = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />
})
const TransitionUp = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function AlertDialogSlide({
    open,
    setOpen,
    onOk,
    children,
    position = 'top',
    title,
    ...props
}: TDialog & DialogProps) {
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Dialog
                {...props}
                sx={{
                    '& .MuiDialog-container': {
                        alignItems: position === 'top' ? 'flex-start' : 'flex-end',
                    },
                }}
                open={open}
                TransitionComponent={position === 'top' ? TransitionDown : TransitionUp}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="fw-600 fz-16 font-default">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" className="font-default fz-14">
                        {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="font-default fw-600">
                        いいえ
                    </Button>
                    <Button onClick={onOk} color="error" className="font-default fw-600">
                        はい
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
