import NoRowsOverlay from '@/components/atoms/NoRowOverlay'
import { LinearProgress } from '@mui/material'

export const tableConfigure = {
    hideFooter: true,
    hideFooterPagination: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
    disableSelectionOnClick: true,
    disableDensitySelector: true,
    components: {
        NoRowsOverlay: NoRowsOverlay,
        LoadingOverlay: LinearProgress,
    },
}
