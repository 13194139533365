import { ButtonProps } from '@mui/material'
import React from 'react'

export default function ButtonRounded({ types = 'filled', ...props }: ButtonProps & { types?: 'filled' | 'outline' }) {
    return (
        <button
            {...props}
            className={`rounded-btn flex-center fw-600 fz-14 font-default ${
                types === 'filled' ? 'filled' : 'outline'
            } ${props.disabled && 'rounded-btn-disabled'} ${props.className}`}
        >
            {props.children}
        </button>
    )
}
