import theme from '@/theme'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { FormLabel, MenuItem, Select, SelectProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import ErrorText from '../ErrorText'

export type SelectSizeProps = 'small' | 'medium'
export interface MenuItemProps {
    id: number | string
    value: string | number
}
interface SelectFieldProps {
    menuItems: Array<MenuItemProps>
    size?: SelectSizeProps
    helperText?: string | null
    isRequired?: boolean
    showEmpty?: boolean
    widthlabel?: number
}

function SelectField({
    menuItems,
    size = 'medium',
    isRequired,
    helperText,
    showEmpty,
    widthlabel = 100,
    sx,
    className,
    fullWidth = true,
    ...selectProps
}: SelectFieldProps & SelectProps) {
    const [open, setOpen] = useState(false)

    const useStyles = makeStyles({
        root: {
            width: '100%',
            borderRadius: 5,
            backgroundColor: theme.colors.white,
            '&:hover': {
                backgroundColor: theme.colors.white,
                cursor: 'pointer',
            },
        },
        inputOutlined: {
            height: '38px !important',
            padding: '0 14px',
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            marginLeft: 5,
        },
        label: {
            fontSize: 14,
            fontWeight: 600,
            color: '#000',
            marginBottom: 5,
        },
        disabled: {
            backgroundColor: '#BABABA',
            color: theme.colors.white,
        },
    })

    const { root, icon, inputOutlined, disabled } = useStyles()

    return (
        <div className={`${fullWidth ? 'full-width' : null}`}>
            {selectProps?.label && (
                <FormLabel className="display-block fw-600 fz-14 mb-10" style={{ width: widthlabel }}>
                    {selectProps.label} {isRequired && <label className="fz-12 required-text ml-10"></label>}
                </FormLabel>
            )}
            <div className="full-width">
                <Select
                    variant="outlined"
                    className={`${root} ${className}`}
                    classes={{ icon, outlined: inputOutlined, disabled }}
                    sx={{
                        fontSize: 14,
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        ...sx,
                    }}
                    size={size}
                    open={open}
                    fullWidth={fullWidth}
                    onClick={() => setOpen(!open)}
                    displayEmpty
                    renderValue={
                        showEmpty
                            ? (value) => {
                                  const newValue = menuItems.find((item) => item.id === value)?.value
                                  return <span>{(newValue as string) || '--'}</span>
                              }
                            : undefined
                    }
                    MenuProps={{
                        disableScrollLock: true,
                        sx: { maxHeight: 400 },
                    }}
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    {...selectProps}
                >
                    {menuItems.length > 0 &&
                        menuItems.map((item) => (
                            <MenuItem
                                className="fz-14 font-default"
                                style={{ display: 'flex' }}
                                key={item.id}
                                value={item.id}
                            >
                                {item.value}
                            </MenuItem>
                        ))}
                </Select>
                {helperText && <ErrorText sx={{ margin: '4px 0 0 14px' }} text={helperText} />}
            </div>
        </div>
    )
}

export default SelectField
