import { Modal, ModalProps } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export interface SizeProps {
    size: number | `${number}${'px' | '%'}`
}

interface TModalProps {
    width?: SizeProps['size']
    maxWidth?: SizeProps['size']
    minWidth?: SizeProps['size']
    height?: SizeProps['size']
    maxHeight?: SizeProps['size']
    minHeight?: SizeProps['size']
}

function CustomModal(props: ModalProps & TModalProps) {
    const styles = {
        width: props.width || 'auto',
        height: props.height || 'auto',
        maxWidth: props.maxWidth || 'auto',
        maxHeight: props.maxHeight || 'auto',
        minWidth: props.minWidth || 'auto',
        minHeight: props.minHeight || 'auto',
    }

    return (
        <Modal {...props} disableScrollLock>
            <Box className="modal-content" sx={styles}>
                {props.title && <h4 className="text-center my-0">{props.title}</h4>}
                {props.children}
            </Box>
        </Modal>
    )
}

export default CustomModal
